import { ReactComponent as CloseIcon } from '@assets/Icons/close.svg'
import { BOOK_DEMO_URL, BREAKPOINTS, FREE_TRIAL_URL } from '@const'
import { Drawer, Dropdown, Menu } from 'antd'
import { ReactNode, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import HeaderCTAButton from '@pages/Landing/atoms/HeaderCTAButton'

export const featureMenuList: { key: string; label: string; path: string; icon: string }[] = [
  { key: 'contracts', label: '계약 체결 • 계약 관리', path: '/features/contracts', icon: 'chart.png' },
  { key: 'invoices', label: '청구 • 증빙 자동화', path: '/features/invoices', icon: 'funnel.png' },
  { key: 'spaces', label: '지점 • 공간 관리', path: '/features/spaces', icon: 'diagram.png' },
  { key: 'stores', label: '대관 • 서비스 관리', path: '/features/stores', icon: 'calendar.png' },
]

export default function Header(): JSX.Element {
  const { pathname: currentPath } = useLocation()
  const { push } = useHistory()

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const menuList: { key: string; menus: { label: string; key: string; path?: string; target?: string }[] } = {
    key: 'main',
    menus: [
      { key: 'features', label: '주요기능' },
      // { key: 'pricing', label: '가격정책', path: '/pricing' },
      { key: 'contact', label: '도입문의', path: '/contact' },
      {
        key: 'guide',
        label: '가젯가이드',
        path: 'https://gadjet.notion.site/78b2e1111fcf43208f420b1815d7a479',
        target: '_blank',
      },
    ],
  }

  const authMenuList: { button: ReactNode; key: string }[] = [
    { key: 'login', button: <Login to="/login">로그인</Login> },
    // { key: 'bookDemo', button: <HeaderCTAButton label="데모 신청하기" path={BOOK_DEMO_URL} target="_blank" /> },
    { key: 'applyForUse', button: <HeaderCTAButton label="이용 신청하기" path={FREE_TRIAL_URL} target="_blank" /> },
  ]

  const onClickHandleDrawer = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <>
      <HeaderWrapper>
        <InnerSection>
          <Link to="/">
            <Logo src="/images/logo/gadjet-logo.svg" alt="gadjet-logo" />
          </Link>

          <MenuGroup>
            <MenuList>
              {menuList.menus.map(({ key, label, path, target }) => (
                <Dropdown
                  key={key}
                  overlay={
                    key === 'features' ? (
                      <FeatureDropdown selectedKeys={[currentPath]}>
                        {featureMenuList.map(({ label, path, key }) => (
                          <Menu.Item key={key} onClick={() => push(path)}>
                            {label}
                          </Menu.Item>
                        ))}
                      </FeatureDropdown>
                    ) : (
                      <div />
                    )
                  }
                  placement="bottom"
                  className="feature-dropdown"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                >
                  <Link to={{ pathname: path }} target={target}>
                    <MenuItem isCurrentPage={currentPath.includes(key)}>{label}</MenuItem>
                  </Link>
                </Dropdown>
              ))}
            </MenuList>
          </MenuGroup>

          <AuthGroup>
            {authMenuList.map(({ button, key }) => (
              <li key={key}>{button}</li>
            ))}
          </AuthGroup>

          <MobileMenuBtn onClick={onClickHandleDrawer}>
            <HamburgerIcon src="/images/landing/commons/hamburger-icon.svg" alt="hamburger-icon" />
          </MobileMenuBtn>
        </InnerSection>
      </HeaderWrapper>

      <CustomDrawer
        visible={isMobileMenuOpen}
        onClose={onClickHandleDrawer}
        closeIcon={<CloseIcon stroke="#2E2E2E" strokeWidth="2" width="40" height="40" />}
        width={360}
      >
        <MenuList>
          {menuList.menus.map(({ key, label, path, target }) => {
            const isFeature = key === 'features'
            return (
              <Link
                to={{ pathname: path }}
                key={key}
                target={target}
                style={{ width: '100%' }}
                onClick={!isFeature ? onClickHandleDrawer : undefined}
              >
                <MenuItem>{label}</MenuItem>
                {isFeature && (
                  <FeatureMenuGroup>
                    {featureMenuList.map((feature) => (
                      <Link key={feature.key} to={{ pathname: feature.path }} onClick={onClickHandleDrawer}>
                        <MenuItem key={feature.key} $isFeatureItem>
                          <img
                            src={`/images/landing/features/${feature.icon}`}
                            alt={feature.key}
                            width={26}
                            height={26}
                          />
                          {feature.label}
                        </MenuItem>
                      </Link>
                    ))}
                  </FeatureMenuGroup>
                )}
                {isFeature && <Divider />}
              </Link>
            )
          })}
        </MenuList>
        {authMenuList[1].button}
      </CustomDrawer>
    </>
  )
}

const HeaderWrapper = styled.header`
  padding: 15px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(15px);
  position: fixed;
  width: 100%;
  z-index: 3;
  @media ${BREAKPOINTS.lg} {
    padding: 9px 40px;
  }
  @media ${BREAKPOINTS.sm} {
    padding: 9px 20px;
  }
`
const InnerSection = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
`
const Logo = styled.img`
  width: 90px;
  height: 28px;
  @media ${BREAKPOINTS.lg} {
    height: 24px;
  }
`

const MobileMenuBtn = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
`

const HamburgerIcon = styled.img`
  width: 40px;
  height: 40px;
`

const MenuGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`

const MenuList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 12px;

  @media ${BREAKPOINTS.lg} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 36px;
  }
`

type MenuItemStyleProps = {
  isCurrentPage?: boolean
  $isFeatureItem?: boolean
}

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: max-content;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  padding: 10px 14px;
  border-radius: 8px;
  height: 40px;
  color: ${({ isCurrentPage }: MenuItemStyleProps) => (isCurrentPage ? '#0d0d0d' : '#656668')};
  cursor: pointer;
  :hover {
    color: #0d0d0d;
    transition: all 0.3s ease-in-out;
    background: rgba(255, 255, 255, 0.6);
  }

  @media ${BREAKPOINTS.lg} {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: ${({ $isFeatureItem }: MenuItemStyleProps) => (!$isFeatureItem ? '#2e2e2e' : '#777D83')};
    display: flex;
    gap: 8px;
    :hover {
      color: #2e2e2e;
      background: none;
    }
  }
`

const AuthGroup = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 8px;
  @media ${BREAKPOINTS.lg} {
    display: none;
  }
`

const Login = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 40px;
  align-items: center;
  padding: 10px 14px;
  background: none;
  opacity: 0.9;
  backdrop-filter: blur(2px);
  border-radius: 8px;
  border: 1px solid #ffffff6b;
  font-weight: 500;
  font-size: 14px;
  color: #2e2e2e;
  line-height: 20px;
  cursor: pointer;
  :hover {
    color: #2e2e2e;
    background: rgba(255, 255, 255, 0.6);
    transition: all 0.3s ease-in-out;
  }
`

const CustomDrawer = styled(Drawer)`
  display: none;
  @media ${BREAKPOINTS.lg} {
    display: block;
  }
  .ant-drawer-body {
    padding: 40px;
  }
  .ant-drawer-content {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(15px);
  }

  .ant-drawer-close {
    margin-right: 0;
    padding: 0;
  }
  .ant-drawer-header {
    padding: 10px 28px 0px 28px;
    background: transparent;
  }
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.5);
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #9a9c9c;
`
const FeatureDropdown = styled(Menu)`
  border-radius: 12px;
  padding: 12px;
  .ant-dropdown-menu-item {
    padding: 10px 12px;
    border-radius: 8px;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #777d83;
    :hover {
      color: #222222;
      background-color: #edf0f4;
    }
  }
  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected {
    color: #222222;
    background-color: #edf0f4;
  }
`
const FeatureMenuGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 24px 0 36px 0;
`
