import { Styles } from '@types'
import { Alert, Col, Divider, Form, Input, Modal, notification, Radio, Row, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { Bills } from 'gadjet-v2-types/dist/model'
import { TaxInvoice } from 'gadjet-v2-types/dist/popbill/tax-invoice'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { krw } from '@utils/format'
import mask from '@utils/mask'

import BillAPI from '@apis/branch/bill'
import ReceiptAPI from '@apis/branch/receipt'

import { RootState } from '@reducers/index'

import HiddenItems from '@components/atoms/Form/HiddenItems'
import LocalDatePicker from '@components/atoms/LocalDatePicker'
import Loading from '@components/molecules/Loading'

import DetailList from './DetailList'

export type TaxInvoiceUpdate = { receiptId: number; modifyCode: number; orgNTSConfirmNum: string; json: TaxInvoice }

type Props = {
  hqId: number
  branchId: number
  billId: number
  receiptId?: number
  visible: boolean
  onClose: () => void
  onDone: () => void
}

export default function TaxInvoiceModal({
  hqId,
  branchId,
  billId,
  receiptId,
  visible,
  onClose,
  onDone,
}: Props): JSX.Element {
  const branch = useSelector((state: RootState) => state.branch)
  const [form] = useForm<TaxInvoice>()
  const [bill, setBill] = useState<Partial<Bills>>()
  const [loading, setLoading] = useState(true)

  const [modifyState, setModifyState] = useState<{ modifyCode: number; alerts: React.ReactNode[] }>({
    modifyCode: 1,
    alerts: [''],
  })

  const { business } = branch
  const isModify = useMemo(() => !!receiptId, [receiptId])

  const getSumDetailList = (detailList: TaxInvoice['detailList']) => {
    if (!detailList) return { supplyCostTotal: '0', taxTotal: '0', totalAmount: '0' }

    let supplyCostTotal = 0
    let taxTotal = 0

    detailList.forEach(({ supplyCost = '0', tax = '0' }) => {
      supplyCostTotal += Number(supplyCost)
      taxTotal += Number(tax)
    })

    const totalAmount = supplyCostTotal + taxTotal

    return { supplyCostTotal: String(supplyCostTotal), taxTotal: String(taxTotal), totalAmount: String(totalAmount) }
  }

  const onOk = async () => {
    if (!billId) return
    try {
      const values = await form.validateFields()
      const sumDetailList = getSumDetailList(values.detailList)

      if (values.invoiceeType === '외국인') {
        values.remark1 = values.invoiceeCorpNum
        values.invoiceeCorpNum = '9999999999999'
      }

      const taxInvoice: TaxInvoice = {
        ...values,
        ...sumDetailList,
        writeDate: dayjs(values.writeDate).format('YYYYMMDD'),
        detailList: values.detailList?.map((detail) => ({
          ...detail,
          purchaseDT: detail.purchaseDT ? dayjs(detail.purchaseDT).format('YYYYMMDD') : undefined,
        })),
      }

      setLoading(true)

      let isSuccess = false
      let message = ''

      if (!receiptId) {
        const { data } = await ReceiptAPI.issueTaxInvoice({ hqId, branchId }, { billId, taxInvoice })
        isSuccess = data.isSuccess
        message = data.message
      }
      if (receiptId) {
        const { data } = await ReceiptAPI.updateTaxInvoice({ hqId, branchId, receiptId }, { billId, taxInvoice })
        isSuccess = data.isSuccess
        message = data.message
      }

      if (!isSuccess) {
        notification.error({ message })
      }

      if (isSuccess) {
        notification.success({ message })
        onDone()
        onClose()
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const onChangeModifyCode = (modifyCode: number) => {
    if (modifyCode === 1) {
      setModifyState({
        modifyCode,
        alerts: [
          <span>
            세금계산서 효력에 영향을 주는 <b>필요적 기재사항</b>이나 업무 편의를 위해 작성하는 <b>임의적 기재사항</b>
            등을 착오 또는 착오 외의 사유로 잘못 작성하거나, 세율을 잘못 적용하여 신고한 경우 이용하는 수정사유입니다.
          </span>,
          <span>기존 발행 된 세금계산서가 취소되고, 새 세금계산서가 발행됩니다.</span>,
          <span>
            <b>과세/영세</b> 전자세금계산서를 <b>면세</b> 과세형태로 수정은 불가하며, 반대(면세↔과세/영세) 경우도
            불가합니다.
          </span>,
        ],
      })
    }
    if (modifyCode === 2) {
      setModifyState({
        modifyCode,
        alerts: [
          <span>
            일부 금액의 계약의 해지 등을 포함하여 공급가액의 증가 또는 감소가 발생한 경우 이용하는 수정사유입니다.
          </span>,
          <span>
            작성일자는 <b>공급가액에 변동이 발생된 날</b>을 기재하세요.
          </span>,
          <span>
            변동된 차액만큼의 가격을 입력하세요. 공급가가 증가했을 경우 증가한 만큼의 금액을, 공급가가 감소했을 경우
            감소한 만큼의 금액을 입력해주시면 됩니다.
          </span>,
        ],
      })
    }
    if (modifyCode === 4) {
      setModifyState({
        modifyCode,
        alerts: [
          <span>재화 또는 용역/서비스가 공급되지 아니하였거나 계약이 해지된 경우 이용하는 수정사유입니다.</span>,
          <span>
            작성일자는 <b>계약이 해제된 날</b>을 기재하세요.
          </span>,
          <span>
            수정사유가 <b>계약의 해제</b>일 경우 원본 세금계산서와 동일한 내용의 부(-) 세금계산서가 발행됩니다.
          </span>,
        ],
      })
    }
    if (modifyCode === 6) {
      setModifyState({
        modifyCode,
        alerts: [
          <span>
            수정사유가 <b>착오에 의한 이중발급</b>일 경우 원본 세금계산서와 동일한 내용의 부(-) 세금계산서가 발행됩니다.
          </span>,
        ],
      })
    }
  }

  const getBill = async () => {
    if (!billId) return
    form.resetFields()
    setLoading(true)
    const { data } = await BillAPI.getBill({ hqId, branchId, billId })
    setBill(data)

    const { spaces, additions, surcharges, startDate, note: invoiceNote } = data
    const { tenant, director, receiver } = data?.contract || {}

    let supplyCostTotal = 0
    let taxTotal = 0
    let totalAmount = 0

    const items = [...(spaces || []), ...(additions || []), ...(surcharges || [])]

    const detailList: TaxInvoice['detailList'] = items.map(({ name, price, vat }, index) => {
      supplyCostTotal += price
      taxTotal += vat
      totalAmount += price + vat

      return {
        serialNum: index + 1,
        purchaseDT: startDate,
        itemName: name,
        supplyCost: String(price),
        tax: String(vat),
      }
    })

    let invoiceeType: TaxInvoice['invoiceeType'] = '사업자'
    if (tenant?.type === 'local') invoiceeType = '개인'
    if (tenant?.type === 'foreigner') invoiceeType = '외국인'

    const [r1, r2] = receiver?.invoice || []
    form.setFieldsValue({
      detailList,
      taxType: '과세',
      purposeType: '영수',
      supplyCostTotal: String(supplyCostTotal),
      taxTotal: String(taxTotal),
      totalAmount: String(totalAmount),
      writeDate: dayjs().format('YYYYMMDD'),

      invoicerCorpNum: business?.businessNumber,
      invoicerTaxRegID: business?.identityNumber,
      invoicerCorpName: business?.name,
      invoicerCEOName: business?.director,
      invoicerAddr: business?.address,
      invoicerBizType: business?.businessType,
      invoicerBizClass: business?.businessClass,
      invoicerContactName: branch.name,
      invoicerEmail: branch.email,

      invoiceeType,
      invoiceeCorpNum: tenant?.businessNumber,
      invoiceeTaxRegID: tenant?.identityNumber, // 종사업장번호
      invoiceeCorpName: tenant?.name,
      invoiceeCEOName: director?.name,
      invoiceeAddr: tenant?.address,
      invoiceeBizType: tenant?.businessType,
      invoiceeBizClass: tenant?.businessClass,
      invoiceeContactName1: r1?.name,
      invoiceeEmail1: r1?.email || tenant?.email,
      invoiceeContactName2: r2?.name,
      invoiceeEmail2: r2?.email,

      remark1: '',
      note: invoiceNote || '',
    })

    setLoading(false)
  }

  const getReceipt = async () => {
    if (!receiptId) return
    setLoading(true)
    const { data: receipt } = await ReceiptAPI.getReceiptDetail({ hqId, branchId, receiptId })
    form.setFieldsValue({
      ...receipt.taxInvoiceJson,
      orgNTSConfirmNum: receipt.ntsConfirmNumber,
      modifyCode: 1,
      note: receipt?.note || '',
    })
    onChangeModifyCode(1)
    setBill({ billId: receipt.billId })
    setLoading(false)
  }

  useEffect(() => {
    if (!visible) return

    getReceipt()
    getBill()
  }, [visible])

  return (
    <Modal visible={visible} onCancel={onClose} title="세금계산서" width="1000px" onOk={onOk} okText="세금계산서 발행">
      <Loading loading={loading}>
        <Form<TaxInvoice> form={form} layout="vertical">
          {isModify && (
            <>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item label="수정사유" name="modifyCode" required>
                    <Select<number> onChange={onChangeModifyCode}>
                      <Select.Option value={1}>기재사항 착오정정</Select.Option>
                      <Select.Option value={2}>공급가액 변동</Select.Option>
                      {/* <Select.Option value={3}>환입</Select.Option> */}
                      <Select.Option value={4}>계약의 해제</Select.Option>
                      {/* <Select.Option value={5}>내국신용장 사후개설</Select.Option> */}
                      <Select.Option value={6}>착오에 의한 이중발급</Select.Option>
                    </Select>
                  </Form.Item>
                  <Alert
                    type="warning"
                    message={
                      <ul style={{ margin: 0, padding: '0 20px' }}>
                        {modifyState.alerts.map((alert) => (
                          <li key={alert?.toString()}>{alert}</li>
                        ))}
                      </ul>
                    }
                  />
                </Col>
              </Row>
              <Divider />
            </>
          )}
          <Row gutter={20}>
            <Col span={6}>
              <Form.Item
                label="작성일자"
                name="writeDate"
                required
                getValueProps={(value) => ({ value: dayjs(value) })}
                getValueFromEvent={(value) => dayjs(value).format('YYYYMMDD')}
              >
                <LocalDatePicker allowClear={false} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="과세형태" name="taxType" required>
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="과세">과세</Radio.Button>
                  <Radio.Button value="영세">영세</Radio.Button>
                  <Radio.Button value="면세">면세</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="영수/청구" name="purposeType" required>
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="영수">영수</Radio.Button>
                  <Radio.Button value="청구">청구</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="공급자 사업자번호" name="invoicerCorpNum" required {...mask.businessLicense}>
                <Input />
              </Form.Item>
              <Form.Item label="공급자 종사업장" name="invoicerTaxRegID">
                <Input />
              </Form.Item>
              <Form.Item label="공급자 상호" name="invoicerCorpName" required>
                <Input />
              </Form.Item>
              <Form.Item label="공급자 대표자" name="invoicerCEOName" required>
                <Input />
              </Form.Item>
              <Form.Item label="공급자 주소" name="invoicerAddr">
                <Input />
              </Form.Item>
              <Form.Item label="공급자 업태" name="invoicerBizType">
                <Input />
              </Form.Item>
              <Form.Item label="공급자 종목" name="invoicerBizClass">
                <Input />
              </Form.Item>
              <Row gutter={10}>
                <Col span={8}>
                  <Form.Item label="공급자 담당자 성명" name="invoicerContactName">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="이메일" name="invoicerEmail">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={10}>
                <Col>
                  <Form.Item label="공급받는자 구분" name="invoiceeType" required>
                    <Radio.Group buttonStyle="solid">
                      <Radio.Button value="사업자">사업자</Radio.Button>
                      <Radio.Button value="개인">개인</Radio.Button>
                      <Radio.Button value="외국인">외국인</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col flex={1}>
                  <Form.Item noStyle dependencies={['invoiceeType']}>
                    {({ getFieldValue }) => {
                      const invoiceeType = getFieldValue('invoiceeType')

                      let label = '공급받는자 사업자번호'
                      let itemMask = mask.businessLicense

                      if (invoiceeType === '개인') {
                        label = '공급받는자 주민등록번호'
                        itemMask = mask.personalLicense
                      }
                      if (invoiceeType === '외국인') {
                        label = '공급받는자 외국인등록번호'
                        itemMask = mask.personalLicense
                      }

                      return (
                        <Form.Item label={label} name="invoiceeCorpNum" required {...itemMask}>
                          <Input />
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="공급받는자 종사업장" name="invoiceeTaxRegID">
                <Input />
              </Form.Item>
              <Form.Item label="공급받는자 상호" name="invoiceeCorpName" required>
                <Input />
              </Form.Item>
              <Form.Item label="공급받는자 대표" name="invoiceeCEOName" required>
                <Input />
              </Form.Item>
              <Form.Item label="공급받는자 주소" name="invoiceeAddr">
                <Input />
              </Form.Item>
              <Form.Item label="공급받는자 업태" name="invoiceeBizType">
                <Input />
              </Form.Item>
              <Form.Item label="공급받는자 종목" name="invoiceeBizClass">
                <Input />
              </Form.Item>
              <Row gutter={10}>
                <Col span={8}>
                  <Form.Item label="공급받는자 담당자1 성명" name="invoiceeContactName1">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="이메일" name="invoiceeEmail1">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={8}>
                  <Form.Item label="공급받는자 담당자2 성명" name="invoiceeContactName2">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="이메일" name="invoiceeEmail2">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="비고" name="note" extra="세금계산서 발행 시 비고란에 기재됩니다">
                <Input.TextArea style={styles.textarea} showCount maxLength={140} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          {/* 금액 표기 */}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldsValue }) => {
              const detailList: TaxInvoice['detailList'] = getFieldValue('detailList') || []

              const { supplyCostTotal, taxTotal, totalAmount } = getSumDetailList(detailList)

              return (
                <Row>
                  <Col span={8}>
                    <div style={styles.priceLabel}>총 금액</div>
                    <div style={styles.priceValue}>{krw(totalAmount)}</div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.priceLabel}>총 공급가액</div>
                    <div style={styles.priceValue}>{krw(supplyCostTotal)}</div>
                  </Col>
                  <Col span={8}>
                    <div style={styles.priceLabel}>총 세액</div>
                    <div style={styles.priceValue}>{krw(taxTotal)}</div>
                  </Col>
                </Row>
              )
            }}
          </Form.Item>
          <HiddenItems names={['supplyCostTotal', 'taxTotal', 'totalAmount']} />
          <Divider />
          <DetailList bill={bill} form={form} />
        </Form>
      </Loading>
    </Modal>
  )
}

const styles: Styles = {
  priceLabel: { textAlign: 'center', opacity: 0.6 },
  priceValue: { textAlign: 'center', fontSize: '20px' },
  textarea: { height: '200px' },
}
