import { Styles } from '@types'
import { Input, Select, Tag } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { ContractStatus } from 'gadjet-v2-types/dist/type'
import { contractStatus } from 'gadjet-v2-types/dist/type/label'
import { ContractSearch } from 'gadjet-v2-types/dist/type/search'
import { RangeValue } from 'rc-picker/lib/interface'
import { useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'

import SpaceTypeAPI from '@apis/branch/spaceType'
import branchQueryKey from '@apis/queryKey/branch'

import LocalDatePicker from '@components/atoms/LocalDatePicker'
import SearchBox from '@components/molecules/SearchBox'
import SearchItem from '@components/molecules/SearchItem'

type Props = {
  hqId: number
  branchId: number
  search: ContractSearch
  isBranchSwitched: boolean
  onChangeSearch: (search: Partial<ContractSearch>) => void
  onSearch: () => void
}

export default function BranchContractSearchBox({
  hqId,
  branchId,
  isBranchSwitched,
  search,
  onChangeSearch,
  onSearch,
}: Props): JSX.Element {
  const onChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => onChangeSearch({ query: e.target.value })

  const onChangeSpaceType = (_spaceTypes: number[]) => onChangeSearch({ spaceTypes: _spaceTypes })

  const onChangeStatus = (_status: ContractStatus[]) => onChangeSearch({ status: _status })

  // on page load print search
  useEffect(() => {
    console.log('search:::: ', search)
  }, [])

  const onChangeDateRange = (_value: RangeValue<Dayjs> | null) => {
    if (_value === null) {
      onChangeSearch({ date: ['', ''] })
    } else {
      const start = _value?.[0]?.format('YYYY-MM-DD') || search.date?.[0] || ''
      const end = _value?.[1]?.format('YYYY-MM-DD') || search.date?.[1] || ''
      onChangeSearch({ date: [start, end] })
    }
  }

  const rangeValue = useMemo((): [Dayjs | null, Dayjs | null] => {
    const [start, end] = search.date || []
    return [start ? dayjs(start) : null, end ? dayjs(end) : null]
  }, [search])

  const { isLoading, data } = useQuery(
    branchQueryKey.getBranchSpaceTypes(branchId),
    () => SpaceTypeAPI.getSpaceTypes({ hqId, branchId }),
    { refetchOnWindowFocus: false }
  )

  const spaceTypes = useMemo(() => {
    if (!data) return []
    return data.data.map(({ name, spaceTypeId }) => ({
      label: name,
      value: spaceTypeId,
    }))
  }, [data])

  const contractStatusOptions = useMemo(
    (): { key: ContractStatus; label: string }[] => [
      { key: 'before-started', label: contractStatus['before-started'] },
      { key: 'expired', label: contractStatus.expired },
      { key: 'extended', label: contractStatus.extended },
      { key: 'started', label: contractStatus.started },
      { key: 'suspended', label: contractStatus.suspended },
    ],
    []
  )

  useEffect(() => {
    onChangeSearch({
      date: ['', ''],
      dateType: 'start',
    })
  }, [])

  useEffect(() => {
    if (isBranchSwitched) {
      onChangeSpaceType([])
    }
  }, [branchId])

  return (
    <SearchBox onSearch={onSearch}>
      <SearchItem label="날짜 기준" span={2}>
        <Select
          size="large"
          value={search.dateType}
          onChange={(value) => onChangeSearch({ dateType: value })}
          style={styles.select}
        >
          <Select.Option value="start">계약시작</Select.Option>
          <Select.Option value="end">계약종료</Select.Option>
        </Select>
      </SearchItem>

      <SearchItem label="날짜" span={6} helpText="선택한 날짜 기준의 범위에 해당하는 계약서를 검색합니다">
        <LocalDatePicker.RangePicker
          ranges={{
            '이번 달': [dayjs().startOf('month'), dayjs().endOf('month')],
            '지난 달': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
          }}
          size="large"
          style={styles.datePicker}
          value={rangeValue}
          onChange={onChangeDateRange}
        />
      </SearchItem>
      <SearchItem label="공간유형" span={4}>
        <Select
          mode="multiple"
          onChange={onChangeSpaceType}
          size="large"
          value={search.spaceTypes}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
          loading={isLoading}
          options={spaceTypes}
        />
      </SearchItem>

      <SearchItem label="상태" span={6}>
        <Select
          mode="multiple"
          onChange={onChangeStatus}
          size="large"
          value={search.status}
          style={styles.select}
          tagRender={({ label }) => <Tag>{label}</Tag>}
        >
          {contractStatusOptions.map((cs) => (
            <Select.Option key={cs.key} value={cs.key}>
              {cs.label}
            </Select.Option>
          ))}
        </Select>
      </SearchItem>

      <SearchItem label="검색어" span={4}>
        <Input size="large" placeholder="검색어를 입력하세요." value={search.query} onChange={onChangeQuery} />
      </SearchItem>
    </SearchBox>
  )
}

const styles: Styles = {
  datePicker: { width: '100%' },
  select: { width: '100%' },
}
